import App from './App';
import 'formdata-polyfill';

(function ($) {
  new SmoothScroll('a[href*="#"]', {
    speed: 500,
    offset: (anchor) => anchor.id === 'main-content' ? -300 : 0,
  });

  if (window.innerWidth > 767) {
    $('.fixed-menu-icon').midnight();
  }
  
  const app = new App();
  app.init();
})(jQuery);
