import noUiSlider from 'nouislider';
import { calculateSurveyResult } from '../utilities/survey.utilities';
import { PILLARS } from '../constants/survey.constants';

export default class Survey {
  constructor() {
    this.answers = {
      [PILLARS.PROFITABILITY]: 3,
      [PILLARS.SUPPORT]: 3,
      [PILLARS.QUALITY]: 3,
      [PILLARS.EASE_OF_USE]: 3,
      [PILLARS.PRODUCTIVITY]: 3,
    };

    this.page = document.querySelector('.survey-page');
    this.form = document.querySelector('.survey-page .survey-form');
    this.sliders = document.querySelectorAll('.range-slider');
    this.labels = document.querySelectorAll('.survey-page ul.labels li');
    this.submitBtn = document.querySelector('.survey-page a.submit-btn');
    this.industryField = document.getElementById('industry');
    this.errorsContainer = document.querySelector('.survey-page .errors');
    this.pips = [];
  }

  exists() {
    return !!this.form;
  }

  init() {
    this.sliders.forEach(slider => {
      noUiSlider.create(slider, {
        range: {
          min: 1,
          max: 5,
        },
        start: [3],
        step: 1,
        connect: [true, false],
        pips: {
          mode: 'values',
          values: [1,2,3,4,5],
          stepped: true,
        }
      });

      this.handleOnSlide(slider, 3);
      this.registerSliderEvents(slider);
    });

    this.labels.forEach(label => {
      label.addEventListener('click', () => {
        this.handleLabelClick(label);
      });
    });

    // find pips after slider creation
    const pips = document.querySelectorAll('.range-slider .noUi-value');

    pips.forEach(pip => {
      pip.addEventListener('click', () => {
        this.handlePipClick(pip);
      });
    });

    this.submitBtn.addEventListener('click', e => {
      e.preventDefault();
      if (this.isValid()) {
        const result = calculateSurveyResult(this.answers, this.industryField.value);
        const url = window.location.href.split('?')[0];
        window.open(`${url}?result=${result}`, '_self');
      } else {
        this.showError('You must select an industry to proceed.');
      }
    });
  }

  isValid() {
    return this.industryField.value !== '';
  }

  showError(error) {
    this.industryField.classList.add('error');
    this.errorsContainer.innerHTML = `<p>${error}</p>`;
  }

  registerSliderEvents(slider) {
    slider.noUiSlider.on('change', val => {
      this.handleOnChange(slider, parseInt(val[0]));
    });

    slider.noUiSlider.on('slide', val => {
      this.handleOnSlide(slider, parseInt(val[0]));
    });
  }

  handleOnChange(slider, val) {
    this.answers[slider.id] = val;
    this.selectLabel(slider, val);
    this.handleOnSlide(slider, val); // ensures pip is removed
  }

  handleOnSlide(slider, val) {
    // hides pip of currently selected val
    const valuePips = document.querySelectorAll(`#${slider.id} .noUi-value`);
    
    valuePips.forEach(pip => {
      const pipVal = parseInt(pip.dataset.value);

      if (pipVal === val) {
        pip.classList.add('hide');
      } else {
        pip.classList.remove('hide');
      }
    });
  }

  handleLabelClick(label) {
    const val = parseInt(label.dataset.value);
    const slider = label.parentElement.previousElementSibling;

    slider.noUiSlider.set(val);

    setTimeout(() => {
      this.handleOnSlide(slider, val); // ensures pip is removed
      this.selectLabel(slider, val);
    }, 200);
  }

  handlePipClick(pip) {
    const slider = pip.parentElement.parentElement;
    const val = parseInt(pip.dataset.value);

    slider.noUiSlider.set(val);

    setTimeout(() => {
      this.handleOnSlide(slider, val); // ensures pip is removed
      this.selectLabel(slider, val);
    }, 200);
  }

  selectLabel(slider, val) {
    const labels = slider.nextElementSibling.children;

    Array.from(labels).forEach((label, index) => {
      if ((index + 1) === val) {
        label.classList.add('selected');
      } else {
        label.classList.remove('selected');
      }
    });
  }
}
