import axios from 'axios';
import { isEmail } from 'validator';
import Cookies from 'js-cookie';

export default class LoginForm {
  constructor() {
    this.modal = document.querySelector('.unregistered-modal');
    this.blogListings = document.querySelector('.blog-listings');
    this.form = document.querySelector('form.login-form');
    this.emailField = document.querySelector('form.login-form #email');
    this.submitBtn = document.querySelector('form.login-form a.btn');
    this.submitBtnLabel = document.querySelector('form.login-form a.btn span');
    this.errorContainer = document.querySelector('form.login-form .errors');
    this.mainMenu = document.querySelector('nav.main-menu');
  }

  exists() {
    return !!this.form;
  }

  init() {
    this.submitBtn.addEventListener('click', () => {
      this.handleFormSubmit();
    });

    this.form.addEventListener('submit', e => {
      e.preventDefault();
      this.handleFormSubmit();
    });
  }

  handleFormSubmit() {
    const error = this.validateForm();

    this.submitBtnLabel.innerHTML = 'Verifying...';

    if (error) {
      this.displayError(error);
    } else {
      this.emailField.classList.remove('error');
      this.submitForm();
    }
  }

  validateForm() {
    const email = this.emailField.value;

    if (email === '' || email === null) {
      return 'Please enter the email address you registered';
    } else if (!isEmail(email)) {
      return 'Please enter a valid email address.';
    }

    return null;
  }

  displayError(error) {
    this.submitBtnLabel.innerHTML = 'Unlock Content';
    this.emailField.classList.add('error');
    this.errorContainer.innerHTML = `<p>${error}</p>`;
  }

  async submitForm() {
    const url = this.form.getAttribute('action');
    let result ;

    try {
      result = await axios.get(url, {
        params: { email: this.emailField.value },
      }).then(res => res.data);
    } catch (error) {
      this.displayError('There was an issue processing your request. Please try again later.');
    }

    if (result.error) {
      this.displayError(result.message);
    } else {
      Cookies.set('MetrohmLogin', result.value, {
        expires: 1,
      });
      this.modal.style.display = 'none';
      this.blogListings.classList.remove('locked');
      this.mainMenu.classList.remove('logged-out');
    }
  }
}
