export default class MainMenu {
  constructor() {
    this.icons = document.querySelectorAll('a.menu-icon');
    this.menu = document.querySelector('nav.main-menu');
    this.body = document.querySelector('body');
  }

  exists() {
    return this.icons && this.menu;
  }

  init() {
    this.icons.forEach(icon => {
      icon.addEventListener('click', e => {
        e.preventDefault();
  
        this.icons.forEach(icon => icon.classList.toggle('open'));
        this.menu.classList.toggle('open');
        this.body.classList.toggle('modal-open');
      });
    });
  }
}
