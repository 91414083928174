import { tns } from 'tiny-slider/src/tiny-slider';

export default class QuoteSlider {
  constructor() {
    this.sliders = document.querySelectorAll('.quote-slider');
  }

  exists() {
    return this.sliders.length > 0;
  }

  init() {
    this.sliders.forEach(slider => {
      tns({
        autoplay: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        container: slider,
        controlsText: [
          `
            <i class="far fa-chevron-left"></i>
            <span class="sr-only">Previous</span>
          `,
          `
            <i class="far fa-chevron-right"></i>
            <span class="sr-only">Next</span>
          `,
        ],
      });
    });
  }
}
