import { tns } from 'tiny-slider/src/tiny-slider';

export default class PpcSlider {
  constructor() {
    this.sliders = document.querySelectorAll('.ppc-slides');
  }

  exists() {
    return this.sliders.length > 0;
  }

  init() {
    this.sliders.forEach(slider => {
      tns({
        container: slider,
        //autoHeight: true,
        navPosition: 'bottom'
      });
    });
  }
}
