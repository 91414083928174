import Cookies from 'js-cookie';

const COOKIE_NAME = 'DisableGoogleAnalytics';

export default class DisableAnalyticsLink {
  constructor() {
    this.link = document.querySelector('a.disable-analytics');
  }

  exists() {
    return !!this.link;
  }

  init() {
    this.link.addEventListener('click', e => {
      e.preventDefault();
      Cookies.set(COOKIE_NAME, true);
      this.link.innerHTML = 'Google Analytics has been disabled';
    });
  }
}
