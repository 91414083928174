export default class ContactUsLink {
  constructor() {
    this.link = document.querySelector('a.contact-us');
  }

  exists() {
    return !!this.link;
  }

  init() {
    this.link.addEventListener('click', e => {
      window.gtag('event', 'contact-us', {
        'event_category' : 'links',
        'event_label' : 'Contact us link clicked',
      });
    });
  }
}
