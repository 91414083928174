export default class Modals {
    constructor() {
        this.triggers = document.querySelectorAll('[data-modal]');
    }

    exists() {
        return this.triggers.length > 0;
    }

    init() {
        for (var i=0; i<this.triggers.length; i++) {
            let trigger = this.triggers[i];

            trigger.addEventListener('click', function(event){
                let modal = document.getElementById(trigger.dataset.modal);
                let video = modal.querySelector('iframe');
                modal.classList.toggle('ppc-modal-active');

                if (video) {
                    var player = new Vimeo.Player(video);
                    if (modal.classList.contains('ppc-modal-active')) {
                        player.play();
                    } else {
                        player.pause();
                        player.setCurrentTime(0);
                    }
                }
            });
        }
    }
}
