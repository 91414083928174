import simpleParallax from 'simple-parallax-js';

export default class Footer {
  constructor() {
    this.footer = document.querySelector('footer.main');
    this.parallaxImage = document.querySelector('footer.main .parallax-image');
  }

  exists() {
    return !!this.footer;
  }

  init() {
    new simpleParallax(this.parallaxImage);
  }
}
