export default class Modal {
  constructor() {
    this.modal = document.querySelector('.video-modal');
    this.buttons = document.querySelectorAll('.toggle-video');
    this.iframe = document.querySelector('.video-modal iframe');
    if (this.iframe) {
      this.src = this.iframe.getAttribute('src');
    }
    this.url = window.location.href;
  }

  exists() {
    return this.modal && this.buttons && this.iframe;
  }

  isActive() {
    return this.modal.classList.contains('video-modal-active');
  }

  activate() {
    this.iframe.setAttribute('allow', 'autoplay; fullscreen');
    this.iframe.setAttribute('src', this.src);
  }

  deactivate() {
    this.iframe.setAttribute('src', '');
  }

  shouldAutoOpen(url) {
    var url = new URL(url);
    var videoParam = url.searchParams.get("video");
    if (videoParam == 'true') {
      return true;
    }
    return false;
  }

  init() {
    this.iframe.setAttribute('src', '');

    if (this.shouldAutoOpen(this.url)) {
      var that = this;
      setTimeout(function(){
        that.activate();
        that.modal.classList.toggle('video-modal-active');
      }, 250);
      console.log('auto playing...');
    }

    this.buttons.forEach(button => {
      button.addEventListener('click', e => {
        e.preventDefault();

        this.modal.classList.toggle('video-modal-active');
        if (this.isActive()) {
          this.activate();
        } else {
          this.deactivate();
        }
      });
    });
  }
}
