import { INDUSTRIES } from '../constants/survey.constants';

/**
 * 
 * @param {object} answers
 * @param {string} industry
 * @returns {string}
 */
export const calculateSurveyResult = (answers, industry) => {
  const sortedAnswers = Object.entries(answers)
    .sort((a, b) => a[1] - b[1]);

  const [first, second] = sortedAnswers;

  if (first[1] !== second[1]) {
    return first[0];
  }

  const highestValue = first[1];
  const industryValues = getIndustryValues(industry);
  const updatedAnswers = sortedAnswers
    .filter(([name, value]) => value === highestValue)
    .map(([name, value]) => [name, (value + industryValues[name])])
    .sort((a, b) => a[1] - b[1]);
  const [result] = updatedAnswers[0];

  return result;
};

/**
 * 
 * @param {string} industry 
 */
const getIndustryValues = (industry) => {
  return INDUSTRIES[industry];
};
