import Cookies from 'js-cookie';

const COOKIE_NAME = 'MetrohmCookieAgreement';

export default class CookieNotification {
  constructor() {
    this.container = document.querySelector('.cookie-notification');
    this.okButton = document.querySelector('.cookie-notification .btn');
  }

  exists() {
    return !!this.container;
  }

  init() {
    const { container, okButton, setCookie, isCookieSet } = this;

    okButton.addEventListener('click', e => {
      e.preventDefault();
      container.classList.add('hidden');
      setCookie();
    });

    if (!isCookieSet()) {
      setTimeout(() => {
        container.classList.add('show');
      }, 1000);
    }
  }

  setCookie() {
    Cookies.set(COOKIE_NAME, true, {
      expires: 1,
    });
  }

  isCookieSet() {
    return !!Cookies.get(COOKIE_NAME);
  }
}
