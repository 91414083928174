import axios from 'axios';
import { isEmail, isMobilePhone } from 'validator';
import Cookies from 'js-cookie';

export default class RegistrationForm {
  constructor() {
    this.form = document.querySelector('.registration-form form');
    this.submitButton = document.querySelector('.registration-form .submit-button');
    this.requiredFields = document.querySelectorAll('.registration-form .required');
    this.allFields = document.querySelectorAll('.registration-form input');
    this.fieldsContainer = document.querySelector('.registration-form .form-fields');
    this.errorContainer = document.getElementById('error-container');
    this.formContent = document.getElementById('form-content');
    this.thankYouTemplate = document.getElementById('thank-you-template');
  }

  exists() {
    return !!this.form;
  }

  init() {
    this.formUrl = this.form.getAttribute('action');

    this.submitButton.addEventListener('click', e => {
      e.preventDefault();

      const errors = this.validateForm();

      if (!errors) {
        this.trackEvent();
        this.submitForm();
      }
    });
  }

  /**
   * Validates required fields
   * @returns {boolean}
   */
  validateForm() {
    let errors = false;

    this.requiredFields.forEach(field => {
      const inputGroup = field.closest('.input-group');
      const errorDiv = inputGroup.nextElementSibling;

      if (field.tagName === 'SELECT') {
        
        if (field.value === '') {
          errors = true;
          errorDiv.innerHTML = `<p>This field is required</p>`;
          inputGroup.classList.add('error');
        } else {
          inputGroup.classList.remove('error');
          errorDiv.innerHTML = '';
        }

      } else if (field.type === 'radio') {
        const radios = document.querySelectorAll('input[name="' + field.name + '"]:checked');
        if (! radios.length) {
          errors = true;
          errorDiv.innerHTML = `<p>This field is required</p>`;
        } else {
          errorDiv.innerHTML = '';
        }
      } else {
        const fieldName = field.getAttribute('placeholder').replace('*', '');

        if (field.value === '') {
          errors = true;
          errorDiv.innerHTML = `<p>${fieldName} is required</p>`;
          inputGroup.classList.add('error');
        } else if (field.id === 'email' && !isEmail(field.value)) {
          errors = true;
          errorDiv.innerHTML = `<p>${fieldName} must be a valid email address</p>`;
          inputGroup.classList.add('error');
        } else if (field.id === 'phone' && !isMobilePhone(field.value)) {
          errors = true;
          errorDiv.innerHTML = `<p>${fieldName} must be a valid phone number</p>`;
          inputGroup.classList.add('error');
        } else {
          inputGroup.classList.remove('error');
          errorDiv.innerHTML = '';
        }

      }
    });

    return errors;
  }

  /**
   * Displays errors messages
   * @param {array} errors - list of error messages
   */
  showErrors(errors) {
    const errorElems = errors.map(error => `<p>${error}</p>`).join('');
    this.errorContainer.innerHTML = errorElems;
  }

  async submitForm() {
    const data = this.buildFormData();
    let response;

    try {
      this.submitButton.innerHTML = 'Sending...';
      response = await axios.post(this.formUrl, data);
    } catch (e) {
      this.showErrors(['We are unable to complete your request at this time.  Please try again later']);
    }

    const { error, message, value } = response.data;

    if (error) {
      this.submitButton.innerHTML = 'Request <span>more</span> information';
      this.showErrors([message]);
    } else {
      const firstName = data.get('first');
      this.setCookie(value);
      this.showFormSuccess(firstName);
      this.enablePremiumContent();
      
      if (data.redirect) {
        window.location = data.redirect;
      }
    }
  }

  /**
   * Builds a list of all field ids and values
   * @returns {array}
   */
  buildFormData() {
    const data = new FormData();
  
    this.allFields.forEach(field => {
      if (field.type !== 'radio'){
        data.append(field.id, field.value);
        data[field.id] = field.value;
      } else {
        if (field.checked) {
          data.append(field.name, field.value);
          data[field.name] = field.value;
        }
      }
    });

    return data;
  }

  setCookie(value) {
    Cookies.set('MetrohmLogin', value, {
      expires: 1,
    });
  }

  showFormSuccess(firstName) {
    const source = this.thankYouTemplate.innerHTML;
    const template = Handlebars.compile(source);
    const context = { firstName };
    const html = template(context);

    this.formContent.innerHTML = html;
  }

  enablePremiumContent() {
    const mainMenu = document.querySelector('nav.main-menu');
    mainMenu.classList.remove('logged-out');
  }

  trackEvent() {
    gtag('event', 'register', {
      'event_category' : 'forms',
      'event_label' : 'Registration Form Submitted',
    });
  }
}
