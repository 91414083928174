export default class FlexContainer {
  constructor() {
    this.containers = document.querySelectorAll('.flex-container');
    this.preFooter = document.querySelector('.pre-footer');
  }

  exists() {
    return this.containers.length > 0 && !!this.preFooter;
  }

  init() {
    const { containers } = this;
    const last = containers[containers.length - 1];
    const lastFlex = last.children[last.children.length - 1];
    
    if (lastFlex.className.indexOf('overlap-bottom') > -1) {
      this.preFooter.classList.add('offset-overlap');
    }
  }
}
