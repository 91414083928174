export default class Header {
  static distanceScrolled() {
    return window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
  }

  constructor() {
    this.header = document.querySelector('header.site-header');
    this.body = document.querySelector('body');
    this.currentScrollDistance = 0;
  }

  exists() {
    return !!this.header;
  }

  init() {
    this.body.classList.add('fixed-header'); // show header on load

    this.body.onscroll = () => {
      const distance = Header.distanceScrolled();

      if (distance < this.currentScrollDistance ||  distance < 50) {
        this.body.classList.add('fixed-header');
      } else {
        this.body.classList.remove('fixed-header');
      }

      this.currentScrollDistance = distance;
    };
  }
}