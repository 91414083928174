import Polyfills from './utilities/polyfills';
import QuoteSlider from './components/QuoteSlider';
import MainMenu from './components/MainMenu';
import RegistrationForm from './components/RegistrationForm';
import FlexContainer from './components/FlexContainer';
import Footer from './components/Footer';
import LoginForm from './components/LoginForm';
import Survey from './components/Survey';
import Header from './components/Header';
import CookieNotification from './components/CookieNotification';
import DisableAnalyticsLink from './components/DisableAnalyticsLink';
import ContactUsLink from './components/ContactUsLink';
import Modal from './components/Modal';
import PpcSlider from './components/PpcSlider';
import Modals from './components/Modals';
import PpcForm from './components/PpcForm';

export default class App {
  constructor() {
    this.components = [
      new MainMenu(),
      new QuoteSlider(),
      new RegistrationForm(),
      new FlexContainer(),
      new Footer(),
      new LoginForm(),
      new Survey(),
      new Header(),
      new CookieNotification(),
      new DisableAnalyticsLink(),
      new ContactUsLink(),
      new Modal(),
      new PpcSlider(),
      new Modals(),
    ];
  }

  init() {
    this.components.forEach(component => {
      if (component.exists()) {
        component.init();
      }
    });
    PpcForm();
  }
}
