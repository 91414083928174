let fields = [
    'first',
    'last',
    'company',
    'email',
    'zip'
];

function removeFormErrors() {
    let errors = document.querySelectorAll('.form-field-error');

    if (errors.length) {
        for (var i=0; i<errors.length; i++) {
            errors[i].innerHTML = '';
        }
    }
}

function validateFields() {

    let hasErrors = 0;

    removeFormErrors();

    if (fields.length) {
        for (var i=0; i<fields.length; i++) {
            let field = document.getElementById(fields[i]);
            if (field.value == '') {
                document.getElementById('form-field-error-' + fields[i]).innerHTML = '<span>This field is required</span>';
                hasErrors++;
            }
        }
    }

    return ! hasErrors;
}

export default function(){
    let form = document.querySelector('.ppc-form');

    if (form) {
        form.addEventListener('submit', function(event){

            if (validateFields()) {
                $.ajax({
                    type: "POST",
                    url: '/wp-content/themes/metrohm/hubspot/add/ppc.php',
                    data: $(form).serialize(),
                    success: function(result){
                        window.location.href = $('.ppc-form [name=redirect]').val();
                        console.log(result);
                    },
                    error: function(error) {
                        console.log(error);
                    }
                });
            }

            event.preventDefault();
        });
    }
}