export const PILLARS = {
  PROFITABILITY: 'profitability',
  SUPPORT: 'support',
  QUALITY: 'robustness',
  EASE_OF_USE: 'ease-of-use',
  PRODUCTIVITY: 'productivity',
};

export const INDUSTRIES = {
  chemistry: {
    [PILLARS.PROFITABILITY]: 2,
    [PILLARS.SUPPORT]: 3,
    [PILLARS.QUALITY]: 5,
    [PILLARS.EASE_OF_USE]: 4,
    [PILLARS.PRODUCTIVITY]: 1,
  },
  environmental: {
    [PILLARS.PROFITABILITY]: 1,
    [PILLARS.SUPPORT]: 5,
    [PILLARS.QUALITY]: 3,
    [PILLARS.EASE_OF_USE]: 4,
    [PILLARS.PRODUCTIVITY]: 2,
  },
  pharmaceutical: {
    [PILLARS.PROFITABILITY]: 1,
    [PILLARS.SUPPORT]: 3,
    [PILLARS.QUALITY]: 2,
    [PILLARS.EASE_OF_USE]: 5,
    [PILLARS.PRODUCTIVITY]: 1,
  },
  petrochemical: {
    [PILLARS.PROFITABILITY]: 4,
    [PILLARS.SUPPORT]: 5,
    [PILLARS.QUALITY]: 2,
    [PILLARS.EASE_OF_USE]: 3,
    [PILLARS.PRODUCTIVITY]: 1,
  },
  foodBeverage: {
    [PILLARS.PROFITABILITY]: 1,
    [PILLARS.SUPPORT]: 4,
    [PILLARS.QUALITY]: 3,
    [PILLARS.EASE_OF_USE]: 5,
    [PILLARS.PRODUCTIVITY]: 2,
  },
  energy: {
    [PILLARS.PROFITABILITY]: 4,
    [PILLARS.SUPPORT]: 5,
    [PILLARS.QUALITY]: 2,
    [PILLARS.EASE_OF_USE]: 3,
    [PILLARS.PRODUCTIVITY]: 1,
  },
  academia: {
    [PILLARS.PROFITABILITY]: 5,
    [PILLARS.SUPPORT]: 2,
    [PILLARS.QUALITY]: 3,
    [PILLARS.EASE_OF_USE]: 4,
    [PILLARS.PRODUCTIVITY]: 1,
  },
  other: {
    [PILLARS.PROFITABILITY]: 0,
    [PILLARS.SUPPORT]: 0,
    [PILLARS.QUALITY]: 0,
    [PILLARS.EASE_OF_USE]: 0,
    [PILLARS.PRODUCTIVITY]: 0,
  },
};
